var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"mt-3"},[(_vm.collection)?_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"space-between","align":"center"}},[_c('v-col',[_c('h1',[_vm._v(" Rate "+_vm._s(_vm.collection.name)+" ("+_vm._s(_vm.collection.collection_code)+") ")])]),_c('v-col',{attrs:{"align":"right"}},[_c('ProductFilter',{model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}}),_c('CsvExport',{staticClass:"ml-3",attrs:{"collection":_vm.collection}})],1)],1),_c('v-data-table',{staticClass:"clickable-table-rows",attrs:{"show-expand":"","single-expand":"","headers":_vm.headers,"items":_vm.items,"expanded":_vm.expanded},on:{"update:expanded":function($event){_vm.expanded=$event},"click:row":_vm.toggleExpand},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('ProductMainImage',{attrs:{"collection":_vm.collection,"product":item,"width":32,"height":32}})]}},{key:"item.info_price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.info_price)+" "+_vm._s(item.info_currency)+" ")]}},{key:"item.plan_price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.plan_price)+" "+_vm._s(item.plan_currency)+" ")]}},{key:"item.rating",fn:function(ref){
var item = ref.item;
return [(item.rating)?_c('RatingImage',{attrs:{"rating":item.rating}}):_vm._e()]}},{key:"item.remarks",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.remarks ? "Yes" : "No")+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-0",attrs:{"colspan":headers.length}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('ProductInformation',{attrs:{"collection":_vm.collection,"product":item}}),_c('v-col',{attrs:{"md":"7","cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('RateProduct',{attrs:{"collection":_vm.collection,"product":item},on:{"rateProductSave":function($event){return _vm.goToNextRow()}}})],1),_c('v-col',[_c('v-btn',{attrs:{"color":"primaryDarkest","dark":!_vm.isLoading && _vm.hasChanges,"disabled":_vm.isLoading || !_vm.hasChanges},on:{"click":function($event){return _vm.goToNextRow()}}},[_vm._v("Next")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('ProductSelections',{attrs:{"product":item}})],1)],1),_c('v-row',[_c('v-col',[_c('ProductReferenceDialog',{attrs:{"collection":_vm.collection,"product":item}})],1)],1)],1)],1)],1)],1)]}}],null,false,1309082211)}),_c('v-container',[_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"cols":"6"}},[_c('CollectionInfo',{attrs:{"name":"All","products":_vm.products}})],1),(_vm.hasFilters)?_c('v-col',{attrs:{"cols":"6"}},[_c('CollectionInfo',{attrs:{"name":"Filtered","products":_vm.items}})],1):_vm._e(),_c('v-spacer')],1)],1)],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }